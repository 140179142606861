import axios from "axios";
import { useState } from "react";
import BounceLoader from "react-spinners/BounceLoader";
import fileDownload from "js-file-download";
import { ToastContainer, toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import "./App.css";

function App() {
  const [url, setUrl] = useState(``);
  const [isLoading, setIsLoading] = useState(false);

  const submitForm = async (e) => {
    e.preventDefault();
    if (!url.length) {
      return;
    }
    setIsLoading(true);
    try {
      const ytRes = await axios.get(`/video-details?url=${url}`);
      if (ytRes.data.isInvalid) {
        throw Error(`Invalid YouTube Link`);
      }
      const fileName = ytRes.data.videoDetails.title;
      const res = await axios.get(`/convert?url=${url}`, {
        responseType: `arraybuffer`,
      });
      fileDownload(res.data, `${fileName}.mp3`);
    } catch (err) {
      const message = err.message || "Something went wrong";
      toast(message, { position: "bottom-left", type: "error" });
    } finally {
      setUrl(``);
      setIsLoading(false);
    }
  };

  return (
    <div className="App">
      <h1>YouTube to MP3 Converter</h1>
      {isLoading ? (
        <BounceLoader loading={isLoading} color="#ff4c29" />
      ) : (
        <form onSubmit={submitForm}>
          <input type="text" onChange={(e) => setUrl(e.target.value)} />
          <button>Convert</button>
        </form>
      )}
      <ToastContainer />
    </div>
  );
}

export default App;
